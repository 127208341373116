<template>
  <v-dialog
    :width="$vuetify.breakpoint.smAndDown ? '100%' : '30%'"
    v-model="dialog"
    persistent
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
        @click="fetchTattooers"
        :style="absolute ? 'position: absolute; right: 95px' : ''"
      >
        <v-icon large>mdi-plus-circle</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ $t("add", { name: $tc("artist") }) }}
      </v-card-title>
      <div class="close">
        <v-btn
          icon
          @click="
            dialog = false;
            action = null;
          "
          small
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form
        action
        data-vv-scope="extra-tattooer-form"
        @submit.prevent="validateForm('extra-tattooer-form')"
      >
        <v-card-text>
          <v-row justify="center">
            <v-col class="py-0" cols="8">
              <label v-text="$tc('artist')"></label>
              <v-autocomplete
                outlined
                hide-details
                dense
                v-model="tattooer_id"
                :items="tattooers"
                item-value="tattooer_id"
                :item-text="
                  (x) =>
                    `${x.tattooer.user.first_name} ${
                      x.tattooer.user.last_name ? x.tattooer.user.last_name : ''
                    }`
                "
                data-vv-validate-on="blur"
                v-validate="'required'"
                :error-messages="errors.collect('extra-tattooer-form.tattooer')"
                data-vv-name="tattooer"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="
              dialog = false;
              action = null;
            "
            style="height: 25px"
            elevation="0"
            class="mr-3"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            style="color: #363533; height: 25px; padding-top: 10px !important"
            elevation="0"
            type="submit"
          >
            <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
            {{ $t("save", { name: "" }) }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AddExtraTattooer",
  props: ["appointment", "absolute"],

  data() {
    return {
      dialog: false,
      tattooer_id: null,
      tattooers: [],
    };
  },
  mounted() {
    this.fetchTattooers();
  },
  methods: {
    select(type) {
      this.action = type;
      if (this.action === "existing") {
        this.fetchCustomers();
      } else {
      }
    },
    ...mapActions("appointments", ["addExistingTattooer"]),
    async validateForm(scope) {
      let result = await this.$validator.validateAll(scope);
      if (result) {
        this.addExistingTattooer({
          id: this.appointment.id,
          data: { tattooer_id: this.tattooer_id },
        }).then(() => {
          this.dialog = false;
          this.action = null;
          this.tattooer_id = null;
          this.$emit("update");
        });
      }
    },
    ...mapActions("studios", ["getStudioTattooersByDate"]),
    fetchTattooers() {
      console.log({
        studio_id: this.appointment.studio_id,
        appointment_id: this.appointment.id,
      });
      this.getStudioTattooersByDate({
        studio_id: this.appointment.studio_id,
        appointment_id: this.appointment.id,
      }).then((response) => {
        //console.log(response);
        //console.log(this.appointment.type);

        response = response.filter((t) => {
          let ta = t.tattooer.type_artist.find((ta) => {
            console.log(ta.name);
            console.log(ta.name === this.appointment.type);
            return ta.name === this.appointment.type;
          });
          let et = this.appointment.extra_tattooers
            .map((et) => et.id)
            .includes(t.tattooer_id);
          if (ta && !et) return true;
          return false;
        });
        this.tattooers = response;
      });
    },
  },
};
</script>
<style scoped lang="sass">
label
  //line-height: 1
  height: 25px
  display: block
</style>